<template>
    <vue-countdown-timer
      @start_callback="startCallBack('event started')"
      @end_callback="endCallBack('event ended')"
      :start-time="'2021-11-12 12:00:00'"
      :end-time="end_at?end_at:endAt"
      :interval="1000"
      :start-label="'Plateslipp for Lytteklubb #9 om'"
      :end-label="'Lytteklubben starter om'"
      label-position="begin"
      show-zero="false"
      :end-text="'Lytteklubben har startet. Logg på livet!'"
      :day-txt="' dager '"
      :hour-txt="' timer '"
      :minutes-txt="' minutter '"
      :seconds-txt="' sekunder '">
      <template slot="start-label" slot-scope="scope">
        <div v-if="scope.props.startLabel !== '' && scope.props.tips && scope.props.labelPosition === 'begin'"><h3>{{scope.props.startLabel}}</h3></div>
        <img v-if="scope.props.endLabel !== '' && !scope.props.tips && scope.props.labelPosition === 'begin'" :src="image">
        <div v-if="scope.props.endLabel !== '' && !scope.props.tips && scope.props.labelPosition === 'begin'"><h3>{{scope.props.endLabel}}</h3></div>
      </template>

      <template slot="countdown" slot-scope="scope">
        <h1><span>{{scope.props.days}}</span><i>{{scope.props.dayTxt}}</i></h1>
        <h1><span>{{scope.props.hours}}</span><i>{{scope.props.hourTxt}}</i></h1>
        <h1><span>{{scope.props.minutes}}</span><i>{{scope.props.minutesTxt}}</i></h1>
        <h1><span>{{scope.props.seconds}}</span><i>{{scope.props.secondsTxt}}</i></h1>
      </template>

      <template slot="end-label" slot-scope="scope">
        <div v-if="scope.props.startLabel !== '' && scope.props.tips && scope.props.labelPosition === 'end'"><h3>{{scope.props.startLabel}}</h3></div>
        <div v-if="scope.props.endLabel !== '' && !scope.props.tips && scope.props.labelPosition === 'end'"><h3>{{scope.props.endLabel}}</h3></div>
      </template>

      <template slot="end-text" slot-scope="scope">
        <div><h1>{{ scope.props.endText}}</h1></div>
      </template>
    </vue-countdown-timer>
</template>

<script >
export default {
  name: 'Timer',
  methods: {
    startCallBack: function(x) {
      console.log(x);
    },
    endCallBack: function(x) {
      console.log(x);
    },
  },
  data() {
      return {
          endAt:  new Date(2021, 10, 13, 18, 0, 0),
          image: 'https://media.pitchfork.com/photos/5929b585c0084474cd0c242e/1:1/w_320/ad25691c.jpg'
      }
  }
};
</script>
<style scoped>
  h1 {
      text-align: center;
      color: #1a1a1a;
      box-sizing: border-box;
      font-family: Tiempos Headline,Tiempos,Georgia,serif;
      font-style: italic;
      margin: 0;
  }
  h3 {
      font-family: Tiempos Headline,Tiempos,Georgia,serif;
      text-rendering: optimizeLegibility;
      text-align: center;
      color: #1a1a1a;
      box-sizing: border-box;
  }
</style>