<template>
  <div>
     <carousel-3d :height=500 :width=500>
      <slide :index="0">
        <h4>Lytteklubb #3</h4>
        <h3>Eivind Palm</h3>
        <img src="../assets/eivind.png" width="100">
        <hr>
        <h2>Kanye West</h2>
        <h1>My Beautiful Dark Twisted Fantasy</h1>
        <img src="https://media.pitchfork.com/photos/5929ac7fc0084474cd0c1583/1:1/w_320/831179e9.jpeg">
      </slide>
      <slide :index="1">
        Slide 2 Content
      </slide>
    </carousel-3d>
  </div>
</template>

<script>

export default {
  name: 'Historikk',
}
</script>

<style>
  .carousel-3d-slide {
    padding: 20px;
    background-color: unset;
  }
/* h1 {
    text-align: center;
    color: #1a1a1a;
    box-sizing: border-box;
    font-family: Tiempos Headline,Tiempos,Georgia,serif;
    font-style: italic;
    margin: 0;
}
h2 {
    font-family: Walfork,Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-rendering: optimizeLegibility;
    text-align: center;
    color: #1a1a1a;
    box-sizing: border-box;
    margin: 0;
}
h3 {
    text-rendering: optimizeLegibility;
    text-align: center;
    color: #1a1a1a;
    box-sizing: border-box;
    font-family: Tiempos Headline,Tiempos,Georgia,serif;
    font-style: italic;
    margin: 0;
}
h4 {
    font-family: Walfork,Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-rendering: optimizeLegibility;
    text-align: center;
    color: #1a1a1a;
    box-sizing: border-box;
    margin: 0;
} */

</style>
