import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Historikk from '../views/Historikk.vue'
import Carousel3d from 'vue-carousel-3d';

Vue.use(Carousel3d);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/historikk',
    name: 'Historikk',
    component: Historikk
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
